import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ElectricMeterDataService, AuthenticationService } from '@/_services';


@Component({
  selector: 'app-electricMeterData',
  templateUrl: './electricMeterData.component.html',
  styleUrls: ['./electricMeterData.component.css'
  ]
})
export class ElectricMeterDataComponent implements OnInit, OnDestroy {
  private gridApi: any;
  private gridColumnApi: any;
  private rowSelection: any;
  private sideBar: any;
  private columnDefs: any;
  private defaultColDef: any;
  private electricMeterData: models.IElectricMeterData[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private electricMeterDataService: ElectricMeterDataService)
  {
    this.columnDefs = [
      { headerName: 'Datum', field: 'date', filter: "agDateColumnFilter", cellRenderer: this.dateFormatter, width: 140 },
      { headerName: 'Temp.', field: 'temperatur', filter: "agNumberColumnFilter", width: 80 },
      { headerName: 'Akt. Verbrauch L1', field: 'currentPowerL1', filter: "agNumberColumnFilter", width: 110 },
      { headerName: 'Akt. Verbrauch L2', field: 'currentPowerL2', filter: "agNumberColumnFilter", width: 110 },
      { headerName: 'Akt. Verbrauch L3', field: 'currentPowerL3', filter: "agNumberColumnFilter", width: 110 },
      { headerName: 'Akt. Verbrauch', field: 'currentPowerSum', filter: "agNumberColumnFilter", width: 80 },
      { headerName: 'HT L1', field: 'highRatePowerConsumedL1', filter: "agNumberColumnFilter", width: 110 },
      { headerName: 'HT L2', field: 'highRatePowerConsumedL2', filter: "agNumberColumnFilter", width: 110 },
      { headerName: 'HT L3', field: 'highRatePowerConsumedL3', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'Hochtarif', field: 'highRatePowerConsumedSum', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'HT Erzeugt L1', field: 'highRatePowerGeneratedL1', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'HT Erzeugt L2', field: 'highRatePowerGeneratedL2', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'HT Erzeugt L3', field: 'highRatePowerGeneratedL3', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'HT Erzeugt', field: 'highRatePowerGeneratedSum', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'NT L1', field: 'lowerRatePowerConsumedL1', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'NT L2', field: 'lowerRatePowerConsumedL2', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'NT L3.', field: 'lowerRatePowerConsumedL3', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'Niedertarif', field: 'lowerRatePowerConsumedSum', filter: "agNumberColumnFilter", width: 90 },

    ];
    this.defaultColDef = { editable: true };
    this.rowSelection = "multiple";

  }

  private loadAllElectricMeterData() {
    this.electricMeterDataService.getAll().subscribe(data => {
      this.electricMeterData = data;
    });
  }

  private dateFormatter(val: any): string {
    var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'};
    return val.value ? (new Date(val.value)).toLocaleString("de-CH", options) : '';
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    
  }


  ngOnInit() {
    this.loadAllElectricMeterData();
  }


  ngOnDestroy() {

  }

}
