import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { UserService, AuthenticationService,AlertService } from '@/_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component(
  {
    selector: 'app-resetPassword',
    templateUrl: './resetPassword.component.html',
    styleUrls: ['./resetPassword.component.css']
  })
export class ResetPasswordComponent implements OnInit, OnDestroy {

  public code: any;
  public userId: any;
  resetPasswordForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService
    
  ) {
   


       
    }

  ngOnInit() {
    this.authenticationService.logout();
    this.userId = this.route.snapshot.queryParamMap.get('userId').toString();
    this.code = this.route.snapshot.queryParamMap.get('code').toString();
      this.resetPasswordForm = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required]
      });
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

  get f() { return this.resetPasswordForm.controls; }

  ngOnDestroy() {

  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.loading = true;
    var user: models.IUser = {
      firstName: this.code,
      id: this.userId,
      inactive: false,
      lastName: "",
      lockoutEnabled: true,
      password: this.f.password.value,
      twoFactorEnabled: false,
      username: this.f.username.value
    };

    this.userService.resetPassword(user).pipe(first()).subscribe(mes => {
      if (mes.Error == false) {
        this.router.navigate([this.returnUrl]);
      } else {
        this.alertService.error(mes.Message);
        this.loading = false;
      }
    },
      error => {
        this.loading = false;
        this.alertService.error("Bitte beachte Passwortrichtlinie");
      });
  }
}
