import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService, WeatherDataService, ElectricMeterDataService, SolarEdgeService } from '@/_services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  private weatherData: models.IWeatherData;
  private overviewData: models.IOverviewData;
  private electricMeterData: models.IElectricMeterData = {} as models.IElectricMeterData;
  private datum: string = "";
  private currentPower: number = 0.000;
  private dayEnergy: number = 0.000;
  private monatEnergy: number = 0.000;
  private jahrEnergy: number = 0.000;
  private gesamtEnergy: number = 0.000;
  private datumElectricMeterData: string = "";
  private niederschlagsstatus: string = "";
  constructor(
    private authenticationService: AuthenticationService,
    private weatherDataService: WeatherDataService,
    private electricMeterDataService: ElectricMeterDataService,
    private solarEdgeService: SolarEdgeService
  ) {
    
    this.weatherData = {} as models.IWeatherData;
    this.weatherDataService.getAktuellWeatherData().subscribe(data => {
      if (data != null) {
        this.weatherData = data;
        this.datum = this.dateFormatter(data.Datum);
        this.niederschlagsstatus = this.niederschlagsFormatter(data.Niederschlagsstatus);
      }
    });
    this.electricMeterData = {} as models.IElectricMeterData;
    this.electricMeterDataService.getAktuellElectricMeterData().subscribe(data => {

      if (data != null) {
        this.electricMeterData = data;
        this.datumElectricMeterData = this.dateFormatter(data.date);
      }
    });

    this.solarEdgeService.getOverview().subscribe(data => {
      console.log("solarEdgeService", data)
      if (data != null) {
        var energie = +data.overview.currentPower.power;
        this.currentPower = energie / 1000;
        var energie = +data.overview.lastDayData.energy;
        this.dayEnergy = energie / 1000;
        var energie = +data.overview.lastMonthData.energy;
        this.monatEnergy = energie / 1000;
        var energie = +data.overview.lastYearData.energy;
        this.jahrEnergy = energie / 1000;
        var energie = +data.overview.lifeTimeData.energy;
        this.gesamtEnergy = energie / 1000;
      }
    });
  }


  setMyStyles(num: number) {
    if (num < 0) {
      let styles = {
        'background-color': 'lightgreen'
      };
      return styles;
    }
    return null;
  }

  private dateFormatter(val: Date): string {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    return val ? (new Date(val)).toLocaleString("de-CH", options) : '';
  }

  private niederschlagsFormatter(val: Number): string {
    if (val == 0) {
      return "Trocken";
    }
    return "Regen";
  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }
}
