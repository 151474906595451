import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from '../_services/message.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class StationDataService {
  constructor
    (
    private http: HttpClient,
    private messageService: MessageService
    )
    { }
  private stationDataUrl = 'api/stationData';

  getAll(): Observable<models.IStationData[]> {
    return this.http.get<models.IStationData[]>(this.stationDataUrl + "/GetAllStationData")
      .pipe(
      tap(_ => this.log('fetched stationData')),
      catchError(this.handleError<models.IStationData[]>('GetAllWStationData'))
      );
  }

  getAktuellStationData(): Observable<models.IStationData> {
    return this.http.get<models.IStationData>(this.stationDataUrl + "/GetAktuellStationData")
      .pipe(
        tap(_ => this.log('fetched stationData')),
      catchError(this.handleError<models.IStationData>('GetAktuellStationData'))
      );
  }

  createStationData(stationData: models.IStationData): Observable<models.IStationData> {
    return this.http.post<models.IStationData>(this.stationDataUrl + "/CreateStationData", stationData, httpOptions)
      .pipe(
      tap(_ => this.log(`added stationData w/ id=${stationData.id}`)),
      catchError(this.handleError<models.IStationData>('addStation'))
    );
  }

  updateStationData(stationData: models.IStationData): Observable<any> {
    return this.http.put(this.stationDataUrl + '/UpdateStationData', stationData, httpOptions)
      .pipe(
      tap(_ => this.log(`updated user id=${stationData.id}`)),
      catchError(this.handleError<models.IStationData>('updateStationData'))
    );
  }

  deleteStationData(stationData: models.IStationData): Observable<any> {
    const id = stationData.id;
    const url = `${this.stationDataUrl}/deleteStationData?id=${id}`;
    return this.http.delete<any>(url, httpOptions).pipe(
      tap(_ => this.log(`deleted stationData id=${id}`)),
      catchError(this.handleError<models.IStationData>('deleteStationData'))
    );
  }


  private log(message: string) {
    console.log("message", message);
    this.messageService.add(`WeatherDataService: ${message}`);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    console.log("Error", operation, result);
    return (error: any): Observable<T> => {
      console.error(error); 
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

}
