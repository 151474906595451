import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@/_services';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-userModal',
  templateUrl: './userModal.component.html',
  styleUrls: ['./userModal.component.css']
})


export class UserModalComponent{

  closeResult: string;
  @Input() data: any;
  @Input() mode: any;
  @Output() modalClose: EventEmitter<string> = new EventEmitter();
  show: boolean;
  user: models.IUser = { firstName: "", id: "08d6667c-886c-a89a-f34d-84da5182e26b", inactive : false, lastName : "", lockoutEnabled : false, password :null,twoFactorEnabled :false, username :""};
  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private userService: UserService) {

  }

  ngOnInit() {
  
    if (this.mode == 1) {
      this.show = true;

    } else {
      this.show = false;
      this.user = this.data;
    }
    console.log("mode", this.data)
  }

  open() {

  }

  close(message: string) {
    this.activeModal.close();
  }

  save() {
    console.log("save", this.user);
    this.userService.createUser(this.user).subscribe(data => {
      this.activeModal.close();
      this.modalClose.emit("OK");
    });
  }
  update() {
    console.log("update", this.user);
    this.userService.updateUser(this.user).pipe(first()).subscribe(() => {
      this.activeModal.close();
      this.modalClose.emit("OK");
    });
  }
}
