import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from '../_services/message.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};



@Injectable({ providedIn: 'root' })
export class UserService {
  constructor
    (
    private http: HttpClient,
    private messageService: MessageService
    )
    { }
  private usersUrl = 'api/users';

  getAll(): Observable<models.IUser[]> {

    return this.http.get<models.IUser[]>(this.usersUrl + "/GetAllUser")
      .pipe(
        tap(_ => this.log('fetched users')),
        catchError(this.handleError('getUsers', []))
      );
  }



  createUser(user: models.IUser): Observable<models.IUser> {
    console.log("createUser", user);
    return this.http.post<models.IUser>(this.usersUrl + "/CreateUser", user, httpOptions).pipe(
      tap(_ => this.log(`added user w/ id=${user.id}`)),
      catchError(this.handleError<models.IUser>('addUser'))
    );
  } 

  forgotPassword(user: models.IUser): Observable<any> {
    console.log("forgotPassword", user);
    return this.http.post<models.IError>(this.usersUrl + "/ForgotPassword", user, httpOptions).pipe(
      tap(_ => this.log(`forgotPassword  userName=${user.username}`)),
      catchError(this.handleError<models.IError>('forgotPassword'))
    );
  }

  resetPassword(user: models.IUser): Observable<any> {
    console.log("resetPassword", user);
    return this.http.post<models.IError>(this.usersUrl + "/ResetPassword", user, httpOptions).pipe(
      tap(_ => this.log(`resetPassword  userName=${user.username}`)),
      catchError(this.handleError<any>('resetPassword'))
    );
  }



  updateUser(user: models.IUser): Observable<any> {
    console.log("user", user);
    return this.http.put(this.usersUrl + '/UpdateUser', user, httpOptions).pipe(
      tap(_ => this.log(`updated user id=${user.id}`)),
      catchError(this.handleError<any>('updateUser'))
    );
  }


  deleteUser(user: models.IUser): Observable<any> {
    const id = user.id;
    const url = `${this.usersUrl}/deleteUser?id=${id}`;
    return this.http.delete<any>(url, httpOptions).pipe(
      tap(_ => this.log(`deleted user id=${id}`)),
      catchError(this.handleError<models.IUser>('deleteUser'))
    );
  }


  private log(message: string) {
    console.log("message", message);
    this.messageService.add(`UserService: ${message}`);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    console.log("Error", operation, result);
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
