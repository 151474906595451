import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ElectricMeterDataService, AuthenticationService } from '@/_services';
import { concat } from 'rxjs/operators';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import '../../app/_models/Models.generated';
import { GridOptions, GridApi } from "ag-grid-community";



@Component({
  selector: 'app-electricMeterDataAnalyseChart',
  templateUrl: './electricMeterDataAnalyseChart.component.html',
  styleUrls: ['./electricMeterDataAnalyseChart.component.css'
  ]
})
export class ElectricMeterDataAnalyseChartComponent implements OnInit {

  data: any;
  fromDate: Date = new Date();
  toDate: Date = new Date();

  radioSelected: string;
  itemsList = [] = [
    {
      name: 'Tage',
      value: '0'
    },
    {
      name: 'Wochen',
      value: '1'
    },
    {
      name: 'Monate',
      value: '2'
    }
  ];

  constructor(private electricMeterDataService: ElectricMeterDataService) {
    this.fromDate.setMonth(this.fromDate.getMonth() - 1);
    this.radioSelected = '0';
    console.log("test",this.itemsList);

  }

  ngOnInit() {
  
    this.loadAllElectricMeterData();
  }


  onItemChange() {
    this.loadAllElectricMeterData();
  }


  private refresh(): void {
    this.loadAllElectricMeterData();
  }

  private loadAllElectricMeterData() {

    console.log(" this.radioSelected", this.radioSelected);
    var sel = 0;
    if (this.radioSelected == '0') {
      sel = 0;
    }
    if (this.radioSelected == '1') {
      sel = 1;
    }
    if (this.radioSelected == '2') {
      sel = 2;
    }
    this.electricMeterDataService.getChartFromTo(this.fromDate, this.toDate, sel).subscribe(_data => {


      this.barChartData = [
        { data: _data.powerConsumedSum, label: 'Energie die Verbraucht wurde' },
        { data: _data.powerGeneratedSum, label: 'Energie die an die BKW geliefert wurde' },
        { data: _data.producePowerSum, label: 'Energie die von der Solaranlage erzeugt wurde' }
      ];
      this.barChartLabels = _data.date;
    });
  }


  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
     };
  public barChartLabels = ['2019'];
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [
    { data: [2], label: 'Energie die Verbraucht wurde' },
    { data: [2], label: 'Energie die an die BKW geliefert wurde' },
    { data: [2], label: 'Energie die von der Solaranlage erzeugt wurde' }
  ];

 




  public lineChartColors: Array<any> = [
    { 
      backgroundColor: 'rgba(155,160,122,1)',
      borderColor: 'rgba(155,160,122,1)',
      pointBackgroundColor: 'rgba(155,160,122,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(155,160,122,1)',
    },

    { // dark grey
      backgroundColor: 'rgb(189, 183, 107)',
      borderColor: 'rgb(189, 183, 107)',
      pointBackgroundColor: 'rgb(189, 183, 107)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(189, 183, 107)',
    },

    { // grey
      backgroundColor: 'rgb(143,188,143)',
      borderColor: 'rgb(143,188,143)',
      pointBackgroundColor: 'rgb(143,188,143)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(143,188,143)',
    }

  ];

}
