import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from '../_services/message.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class DigitalInOutputsService {
  constructor
    (
    private http: HttpClient,
    private messageService: MessageService
    )
    { }
  private digitalInOutputsUrl = 'api/inOutputs';

  getDigiatInputs(): Observable<any> {
    return this.http.get<any>(this.digitalInOutputsUrl + "/GetDigiatInputs")
      .pipe(
      tap(_ => this.log('fetched inputs')),
      catchError(this.handleError<any>('GetDigiatInputs'))
      );
  }

  getDigiatOutputs(): Observable<any> {
    return this.http.get<any>(this.digitalInOutputsUrl + "/GetDigiatOutputs")
      .pipe(
        tap(_ => this.log('fetched outputs')),
      catchError(this.handleError<any>('GetDigiatOutputs'))
      );
  }

  setResetOutputs(output: number, state: boolean): Observable<any> {
    return this.http.post<any>(this.digitalInOutputsUrl + "/SetResetOutputs?output=" + output + "&state=" + state,  httpOptions)
      .pipe(
      tap(_ => this.log(`setResetOutputs output w/ id=${output}`)),
      catchError(this.handleError<any>('setResetOutputs'))
    );
  }


  private log(message: string) {
    console.log("message", message);
    this.messageService.add(`WeatherDataService: ${message}`);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    console.log("Error", operation, result);
    return (error: any): Observable<T> => {
      console.error(error); 
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

}
