import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { UserService, AuthenticationService, AlertService } from '@/_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component(
  {
    selector: 'app-forgotPassword',
    templateUrl: './forgotPassword.component.html',
    styleUrls: ['./forgotPassword.component.css']
  })
  export class ForgotPasswordComponent implements OnInit, OnDestroy {

  public userName: string = "";
  public buttonEnable: boolean = false;
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private ngbModal: NgbModal,
    private alertService: AlertService
    
  ) {
        // redirect to home if already logged in
       
    }

  ngOnInit() {
    this.authenticationService.logout();
    }



  ngOnDestroy() {

  }

  forgotPassword()
  {
    console.log("username", this.userName);
    var user: models.IUser = {
      firstName: "",
      id: "08d6667c-886c-a89a-f34d-84da5182e26b",
      inactive: false,
      lastName: "",
      lockoutEnabled: true,
      password: "",
      twoFactorEnabled: false,
      username: this.userName
    };
    this.userService.forgotPassword(user).subscribe(mes => {
      if (mes.Error == false) {
        this.alertService.success("Sie bekommen ein Email mit einem Link zum Zurücksetzen des Passwortes");
        this.buttonEnable = true;
      } else {
        this.alertService.error(mes.Message);
      }
    });
  }
}
