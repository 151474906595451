import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { StationDataService, AuthenticationService, DigitalInOutputsService } from '@/_services';


@Component({
  selector: 'app-digitalInOutputs',
  templateUrl: './digitalInOutputs.component.html',
  styleUrls: ['./digitalInOutputs.component.css']
})
export class DigitalInOutputsComponent implements OnInit, OnDestroy {
  private inputs: boolean[] = [false, false, false, false, false, false, false, false];
  private outputs: boolean[] = [false, false, false, false, false, false, false, false];

  constructor(
    private authenticationService: AuthenticationService,
    private digitalInOutputsService: DigitalInOutputsService)
  {


  }

  ngOnInit() {
    this.digitalInOutputsService.getDigiatInputs().subscribe(data => {
      console.log("digitalInOutputsService", data);
      this.inputs = data;

    });
    this.digitalInOutputsService.getDigiatOutputs().subscribe(data => {
      console.log("digitalInOutputsService", data);
      this.outputs = data;
    });
  }

  private setOutput(out: number):void
  {
    console.log("setOutput", out)
    var output = this.outputs[out];
    
    this.digitalInOutputsService.setResetOutputs(out, !output).subscribe(data => {
      this.outputs = data;
    });
  }


  ngOnDestroy() {

  }

}
