import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from '../_services/message.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class SolarEdgeService {
  constructor
    (
    private http: HttpClient,
    private messageService: MessageService
    )
    { }
  private electricMeterDataUrl = 'api/solarEdge';

  getOverview(): Observable<models.IOverviewData> {
    return this.http.get<models.IOverviewData>(this.electricMeterDataUrl + "/Overview")
      .pipe(
      tap(_ => this.log('fetched overview')),
      catchError(this.handleError<models.IOverviewData>('Overview'))
      );
  }


  private log(message: string) {
    console.log("message", message);
    this.messageService.add(`SolarEdge: ${message}`);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    console.log("Error", operation, result);
    return (error: any): Observable<T> => {
      console.error(error); 
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

}
