import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { StationDataService, AuthenticationService } from '@/_services';


@Component({
  selector: 'app-stationData',
  templateUrl: './stationData.component.html',
  styleUrls: ['./stationData.component.css']
})
export class StationDataComponent implements OnInit, OnDestroy {
  private gridApi: any;
  private gridColumnApi: any;
  private rowSelection: any;
  private columnDefs: any;
  private defaultColDef: any;
  private stationData: models.IStationData[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private stationDataService: StationDataService)
  {
    this.defaultColDef = { editable: true };
    this.rowSelection = "multiple";

  }

  private loadAllStationData() {
    this.stationDataService.getAll().subscribe(data => {
      this.stationData = data;
      this.columnDefs = [
        { headerName: 'Datum',  field: 'Datum', filter: "agDateColumnFilter", cellRenderer: this.dateFormatter},
        { headerName: 'HwVersion', field: 'HwVersion', filter: "agNumberColumnFilter" },
        { headerName: 'Seriennummer', field: 'Seriennummer', filter: "agNumberColumnFilter", },
        { headerName: 'Sw Version', field: 'SwVersion', filter: "agNumberColumnFilter"},
        { headerName: 'Stationshöhe', field: 'Wetterstationshoehe', filter: "agNumberColumnFilter"}
      ];
      
    });
  }

  private dateFormatter(val: any): string {
    var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'};
    return val.value ? (new Date(val.value)).toLocaleString("de-CH", options) : '';
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.gridApi.sizeColumnsToFit();
  }


  ngOnInit() {
    this.loadAllStationData();

  }


  ngOnDestroy() {

  }

}
