import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { WeatherDataComponent } from './weatherData/weatherData.component';
import { StationDataComponent } from './stationData/stationData.component';
import { UserComponent } from './user/user.component';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from './_guards';
import { ForgotPasswordComponent } from './forgotPassword/forgotPassword.component';
import { ResetPasswordComponent } from './resetPassword/resetPassword.component';
import { DigitalInOutputsComponent } from './digitalInOutputs/digitalInOutputs.component';
import { ElectricMeterDataComponent } from './electricMeterData/electricMeterData.component';
import { ElectricMeterDataAnalyseComponent } from './electricMeterDataAnalyse/electricMeterDataAnalyse.component';
import { ElectricMeterDataAnalyseChartComponent } from './electricMeterDataAnalyseChart/electricMeterDataAnalyseChart.component';

const appRoutes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'weatherData', component: WeatherDataComponent, canActivate: [AuthGuard] },
  { path: 'stationData', component: StationDataComponent, canActivate: [AuthGuard] },
  { path: 'electricMeterData', component: ElectricMeterDataComponent, canActivate: [AuthGuard] },
  { path: 'electricMeterDataAnalyse', component: ElectricMeterDataAnalyseComponent, canActivate: [AuthGuard] },
  { path: 'electricMeterDataAnalyseChart', component: ElectricMeterDataAnalyseChartComponent, canActivate: [AuthGuard] },
  { path: 'user', component: UserComponent, canActivate: [AuthGuard] },
  { path: 'digitalInOutputs', component: DigitalInOutputsComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'forgotPassword', component: ForgotPasswordComponent },
  { path: 'resetPassword', component: ResetPasswordComponent },
  //  { path: 'register', component: RegisterComponent },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);
