import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ElectricMeterDataService, AuthenticationService } from '@/_services';
import { concat } from 'rxjs/operators';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import '../../app/_models/Models.generated';
import { GridOptions, GridApi } from "ag-grid-community";


@Component({
  selector: 'app-electricMeterDataAnalyse',
  templateUrl: './electricMeterDataAnalyse.component.html',
  styleUrls: ['./electricMeterDataAnalyse.component.css'
  ]
})
export class ElectricMeterDataAnalyseComponent implements OnInit, OnDestroy {

  gridApi: GridApi;
  gridColumnApi: any;
  rowSelection: any;
  sideBar: any;
  columnDefs: any;
  defaultColDef: any;
  electricMeterData: models.IElectricMeterData[];
  electricMeterDataTop: models.IElectricMeterData[];
  electricMeterDataDefault: models.IElectricMeterData[];

  fromDate: Date = new Date();
  toDate: Date = new Date();

  kwhHour: string
  kwhDay: string
  kwhMonth: string
  kwhYear: string

  constructor(
    private authenticationService: AuthenticationService,
    private electricMeterDataService: ElectricMeterDataService) {
    this.columnDefs = [
      {
        headerName: 'Datum', field: 'date', width: 140, cellRenderer: this.dateFormatter, 
        filter: 'agDateColumnFilter', filterParams: {
          comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
            const dateParts = cellValue.split("-");
            const dateParts2 = dateParts[2].split("T");
            const cellDate = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts2[0]));
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0
            }
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }

            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          }
        }
      },
      { headerName: 'Temp.', field: 'temperatur', filter: "agNumberColumnFilter", width: 80 },
      { headerName: 'Akt. Verbrauch', field: 'currentPowerSum', filter: "agNumberColumnFilter",  width: 150 },
      { headerName: 'Hochtarif', cellClass: 'rag-amberConsumed', field: 'highRatePowerConsumedSum', filter: "agNumberColumnFilter", cellRenderer: this.currencyCellRenderer, width: 110 },
      { headerName: 'Niedertarif', cellClass: 'rag-amberConsumed', field: 'lowerRatePowerConsumedSum', filter: "agNumberColumnFilter", cellRenderer: this.currencyCellRenderer, width: 110 },
      { headerName: 'Summe', cellClass: 'rag-amberConsumed', field: 'powerConsumedSum', filter: "agNumberColumnFilter", cellRenderer: this.currencyCellRenderer, width: 110 },
      { headerName: 'Hochtarif', cellClass: 'rag-amberGenerated', field: 'highRatePowerGeneratedSum', filter: "agNumberColumnFilter", cellRenderer: this.currencyCellRenderer, width: 110 },
      { headerName: 'Niedertarif', cellClass: 'rag-amberGenerated', field: 'lowerRatePowerGeneratedSum', filter: "agNumberColumnFilter", cellRenderer: this.currencyCellRenderer, width: 110 },
      { headerName: 'Summe', cellClass: 'rag-amberGenerated', field: 'powerGeneratedSum', filter: "agNumberColumnFilter", cellRenderer: this.currencyCellRenderer, width: 110 },
      { headerName: 'Summe', cellClass: 'rag-amberProduce', field: 'producePowerSum', filter: "agNumberColumnFilter", cellRenderer: this.currencyCellRenderer, width: 110 },
      

    ];
    this.defaultColDef = { editable: true };
    this.rowSelection = "multiple";
    this.fromDate.setDate(this.fromDate.getDate() - 1);
    this.fromDate.setMinutes(0);
    this.fromDate.setMilliseconds(0);
    this.fromDate.setSeconds(0);
    if (this.fromDate.getHours() % 2 === 1)
    {
      this.fromDate.setHours(this.fromDate.getHours() - 1);
    }
    console.log("this.fromDate", this.fromDate)

  }

  private refresh(): void {
    this.loadAllElectricMeterData();
  }

  private loadAllElectricMeterData() {
    this.electricMeterDataService.getFromTo(this.fromDate, this.toDate).subscribe(data => {
      this.electricMeterData = data;
      this.electricMeterDataDefault = data;
      this.calc();
      this.gridApi.setRowData(this.electricMeterData);
      this.gridApi.setPinnedTopRowData(this.electricMeterDataTop);
    });
  }


  private dateFormatter(val: any): string {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    return val.value ? (new Date(val.value)).toLocaleString("de-CH", options) : '';
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  private calc() {

    this.electricMeterDataTop = [];
    if (this.electricMeterData != null && this.electricMeterData.length >= 2) {

      var first: models.IElectricMeterData = this.electricMeterData[0];
      var last: models.IElectricMeterData = this.electricMeterData[this.electricMeterData.length - 1];

      var sum: models.IElectricMeterData = {
        highRatePowerConsumedSum: first.highRatePowerConsumedSum - last.highRatePowerConsumedSum,
        lowerRatePowerConsumedSum: first.lowerRatePowerConsumedSum - last.lowerRatePowerConsumedSum,
        highRatePowerGeneratedSum: first.highRatePowerGeneratedSum - last.highRatePowerGeneratedSum,
        lowerRatePowerGeneratedSum: first.lowerRatePowerGeneratedSum - last.lowerRatePowerGeneratedSum,
        powerConsumedSum: first.powerConsumedSum - last.powerConsumedSum,
        powerGeneratedSum: first.powerGeneratedSum - last.powerGeneratedSum,
        producePowerSum: first.producePowerSum - last.producePowerSum,
        
      } as models.IElectricMeterData;

      let firstDate = new Date(first.date);
      let lastDate = new Date(last.date);
      var diff = ((firstDate.valueOf() - lastDate.valueOf()) / 1000 / 60 / 60 /24 );
      console.log("diff", diff);
      var sum1: models.IElectricMeterData = {
        highRatePowerConsumedSum: sum.highRatePowerConsumedSum / diff,
        lowerRatePowerConsumedSum: sum.lowerRatePowerConsumedSum / diff,
        highRatePowerGeneratedSum: sum.highRatePowerGeneratedSum / diff,
        lowerRatePowerGeneratedSum: sum.lowerRatePowerGeneratedSum / diff,
        powerConsumedSum: sum.powerConsumedSum / diff,
        powerGeneratedSum: sum.powerGeneratedSum / diff,
        producePowerSum: sum.producePowerSum / diff
      } as models.IElectricMeterData;

      this.electricMeterDataTop.push(sum);
      this.electricMeterDataTop.push(sum1);
    }
  }

  private currencyCellRenderer(params: any) {

    var usdFormate = new Intl.NumberFormat('de-CH', {

      minimumFractionDigits: 3
    });

    return usdFormate.format(params.value);
  }

  onFilterChanged(event: GridApi) {
    
  }

  onRowDataChanged(event: GridApi) {
   
  }

 

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {

  }




  ngOnInit() {
    this.loadAllElectricMeterData();
  }


  ngOnDestroy() {

  }

}
