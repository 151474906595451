import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from '../_services/message.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class ElectricMeterDataService {
  constructor
    (
    private http: HttpClient,
    private messageService: MessageService
    ) { }
  private electricMeterDataUrl = 'api/modbus';

  getAll(): Observable<models.IElectricMeterData[]> {
    return this.http.get<models.IElectricMeterData[]>(this.electricMeterDataUrl + "/GetAllElectricMeterData")
      .pipe(
        tap(_ => this.log('fetched electricMeterData')),
        catchError(this.handleError<models.IElectricMeterData[]>('GetAllElectricMeterData'))
      );
  }

  getAktuellElectricMeterData(): Observable<models.IElectricMeterData> {
    return this.http.get<models.IElectricMeterData>(this.electricMeterDataUrl + "/GetAktuellElectricMeterData")
      .pipe(
        tap(_ => this.log('fetched electricMeterData')),
        catchError(this.handleError<models.IElectricMeterData>('GetAktuellElectricMeterData'))
      );
  }

  getFromTo(from: Date, to: Date): Observable<models.IElectricMeterData[]> {
    return this.http.get<models.IElectricMeterData[]>(this.electricMeterDataUrl + `/GetFromTo?from=${from.toLocaleString().replace(/\u200E/g, "")}&to=${to.toLocaleString().replace(/\u200E/g, "")}`)
      .pipe(
        tap(_ => this.log('fetched energiePerDayData')),
        catchError(this.handleError<models.IElectricMeterData[]>('GetAll'))
      );
  }

  getChartFromTo(from: Date, to: Date, modus: number): Observable<models.IElectricMeterDataChart> {
    return this.http.get<models.IElectricMeterDataChart>(this.electricMeterDataUrl + `/GetFromToChart?from=${from.toLocaleString().replace(/\u200E/g, "")}&to=${to.toLocaleString().replace(/\u200E/g, "")}&modus=${modus}`)
      .pipe(
        tap(_ => this.log('fetched energiePerDayData')),
        catchError(this.handleError<models.IElectricMeterDataChart>('GetAll'))
      );
  }

  createElectricMeterData(electricMeterData: models.IElectricMeterData): Observable<models.IElectricMeterData> {
    return this.http.post<models.IElectricMeterData>(this.electricMeterDataUrl + "/CreateElectricMeterData", electricMeterData, httpOptions)
      .pipe(
        tap(_ => this.log(`added electricMeterData w/ id=${electricMeterData.id}`)),
        catchError(this.handleError<models.IElectricMeterData>('addElectricMeterData'))
      );
  }

  updateElectricMeterData(electricMeterData: models.IElectricMeterData): Observable<any> {
    return this.http.put(this.electricMeterDataUrl + '/UpdateElectricMeterData', electricMeterData, httpOptions)
      .pipe(
        tap(_ => this.log(`updated user id=${electricMeterData.id}`)),
        catchError(this.handleError<models.IElectricMeterData>('updateElectricMeterData'))
      );
  }

  deleteElectricMeterData(electricMeterData: models.IElectricMeterData): Observable<any> {
    const id = electricMeterData.id;
    const url = `${this.electricMeterDataUrl}/deleteElectricMeterDataUrl?id=${id}`;
    return this.http.delete<any>(url, httpOptions).pipe(
      tap(_ => this.log(`deleted electricMeterData id=${id}`)),
      catchError(this.handleError<models.IElectricMeterData>('deleteElectricMeterData'))
    );
  }


  private log(message: string) {
    console.log("message", message);
    this.messageService.add(`ElectricMeterDataService: ${message}`);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    console.log("Error", operation, result);
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

}
