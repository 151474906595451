import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { WeatherDataService, AuthenticationService } from '@/_services';


@Component({
  selector: 'app-weatherData',
  templateUrl: './weatherData.component.html',
  styleUrls: ['./weatherData.component.css'
  ]
})
export class WeatherDataComponent implements OnInit, OnDestroy {
  private gridApi: any;
  private gridColumnApi: any;
  private rowSelection: any;
  private sideBar: any;
  private columnDefs: any;
  private defaultColDef: any;
  private weatherData: models.IWeatherData[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private weatherDataService: WeatherDataService)
  {
    this.columnDefs = [
      { headerName: 'Datum', tooltipField: "indicator", field: 'Datum', filter: "agDateColumnFilter", cellRenderer: this.dateFormatter, width: 140 },
      { headerName: 'Temperatur', field: 'Lufttemperatur', filter: "agNumberColumnFilter", width: 110 },
      { headerName: 'Abs. Feuchte', field: 'AbsoluteFeuchte', filter: "agNumberColumnFilter", width: 110 },
      { headerName: 'Helligk. Nord', field: 'HelligkeitNord', filter: "agNumberColumnFilter", width: 110 },
      { headerName: 'Regen', field: 'Niederschlagsstatus', filter: "agNumberColumnFilter", width: 80 },
      { headerName: 'Helligk. Süd', field: 'HelligkeitSued', filter: "agNumberColumnFilter", width: 110 },
      { headerName: 'Helligk. Ost', field: 'HelligkeitOst', filter: "agNumberColumnFilter", width: 110 },
      { headerName: 'Helligk. West', field: 'HelligkeitWest', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'Windges.', field: 'MittelWindgeschwindigkeit', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'Windrich.', field: 'MittelWindrichting', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'Rel. Luftdr.', field: 'RelativerLuftdruck', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'Abs. Luftdr.', field: 'AbsoluterLuftdruck', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'Breitengr.', field: 'Breitengrad', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'Längengr.', field: 'Laengengrad', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'Dämmerung', field: 'Daemmerung', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'Globalstr.', field: 'Globalstrahlung', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'Ident', field: 'Identifikation', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'Sensorst.', field: 'Sensorstatus', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'Sonnenr.', field: 'SonnenstandHimmelsrichtung', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'Sonnenwin.', field: 'SonnenstandHoehenWinkel', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'Taupunkt', field: 'Taupunkttemperatur', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'Zeitfor.', field: 'Zeitformat', filter: "agNumberColumnFilter", width: 90 },
      { headerName: 'Geh. Temp.', field: 'GehauseInnentemperatur', filter: "agNumberColumnFilter", width: 90 }
    ];
    this.defaultColDef = { editable: true };
    this.rowSelection = "multiple";

  }

  private loadAllWeatherData() {
    this.weatherDataService.getAll().subscribe(data => {
      this.weatherData = data;
     
    });
  }

  private dateFormatter(val: any): string {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    return val.value ? (new Date(val.value)).toLocaleString("de-CH", options) : '';
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    
  }


  ngOnInit() {
    this.loadAllWeatherData();
  }


  ngOnDestroy() {

  }

}
