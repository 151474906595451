import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { UserService, AuthenticationService } from '@/_services';
//import { ResizeEvent } from 'angular-resizable-element';

import { UserModalComponent } from './modal/userModal.component';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit, OnDestroy {
  private gridApi: any;
  private gridColumnApi: any;
  private rowSelection: any;
  private columnDefs :any;
  private users: models.IUser[] = [];
  private defaultColDef :any;
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private ngbModal: NgbModal
  )
  {
    this.columnDefs = [
      { headerName: 'FirstName', field: 'FirstName', suppressSizeToFit: true },
      { headerName: 'LastName', field: 'LastName' },
      { headerName: 'Username', field: 'Username' },
      { headerName: 'LockoutEnabled', field: "LockoutEnabled", dataType: "bool", cellRenderer: this.bollFormatter, width: 100 },
      { headerName: 'Inactive', field: 'Inactive', dataType: "bool", cellRenderer: this.bollFormatter, width: 100 },
      { headerName: 'TwoFactorEnabled', field: 'TwoFactorEnabled', dataType: "bool", cellRenderer: this.bollFormatter, width: 100 }
    ];
    this.defaultColDef = { editable: true };
    this.rowSelection = "multiple";
  }



  private bollFormatter(val: any): string {
    if (val.value === true)
      return "Ja";
  return "Nein";
  }

  onCellValueChanged(params :any) { 
    console.log("params", params.data)
    this.userService.updateUser(params.data).subscribe(() => {
      this.loadAllUsers()
    });
  }

  onGridReady(params : any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }


 /* onResized(event: ResizedEvent): void {
    console.log("ii");
    this.gridApi.sizeColumnsToFit();
  }*/

  @HostListener('window:resize', ['$event'])
  onResize(event : any) {
    this.gridApi.sizeColumnsToFit();
  }


  ngOnInit() {
    this.loadAllUsers();
  }

  ngOnDestroy() {

  }

  onAddRow() {
    const modal = this.ngbModal.open(UserModalComponent, { size: 'lg' });
    modal.componentInstance.mode = 1;
    modal.componentInstance.modalClose.subscribe(($e: any) => {
      this.loadAllUsers();
    })
  }

  onRemoveSelected() {
    var selectedData: models.IUser = this.gridApi.getSelectedRows();
    const modal = this.ngbModal.open(UserModalComponent, { size: 'lg' });
    var user: models.IUser = {
      id: selectedData[0].Id,
      firstName: selectedData[0].FirstName,
      inactive: selectedData[0].Inactive,
      lastName: selectedData[0].LastName,
      lockoutEnabled: selectedData[0].LockoutEnabled,
      password: selectedData[0].Password,
      twoFactorEnabled: selectedData[0].TwoFactorEnabled,
      username: selectedData[0].Username
    }

    modal.componentInstance.data = user;
    modal.componentInstance.mode = 2;
    modal.componentInstance.modalClose.subscribe(($e:any) => {
      this.loadAllUsers();
    })
  }





  deleteUser(user: models.IUser) {
    this.userService.deleteUser(user).subscribe(() => {
      this.loadAllUsers()
    });
  }

  private loadAllUsers() {
    this.userService.getAll().subscribe(users => {
      this.users = users;
      this.gridApi.sizeColumnsToFit();
    });
  }
}
