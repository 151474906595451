import { NgModule }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// used to create fake backend
import { fakeBackendProvider } from './_helpers';

import { AppComponent }  from './app.component';
import { routing }        from './app.routing';

import { AlertComponent } from './_components';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home/home.component';
import { WeatherDataComponent } from './weatherData/weatherData.component';
import { ElectricMeterDataComponent } from './electricMeterData/electricMeterData.component';
import { ElectricMeterDataAnalyseComponent } from './electricMeterDataAnalyse/electricMeterDataAnalyse.component';
import { ElectricMeterDataAnalyseChartComponent } from './electricMeterDataAnalyseChart/electricMeterDataAnalyseChart.component';
import { StationDataComponent } from './stationData/stationData.component';
import { ForgotPasswordComponent } from './forgotPassword/forgotPassword.component';
import { ResetPasswordComponent } from './resetPassword/resetPassword.component';
import { DigitalInOutputsComponent } from './digitalInOutputs/digitalInOutputs.component';
import { UserComponent } from './user/user.component';
import { UserModalComponent } from './user/modal/userModal.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AgGridModule } from 'ag-grid-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
      HttpClientModule,
      FormsModule, 
      AgGridModule.withComponents([]),
      routing,
      NgbModule.forRoot(),
      ChartsModule,
    ],
    declarations: [
        AppComponent,
        AlertComponent,
      HomeComponent,
      WeatherDataComponent,
      ElectricMeterDataComponent,
      ElectricMeterDataAnalyseChartComponent,
      ElectricMeterDataAnalyseComponent,
      StationDataComponent,
      ForgotPasswordComponent,
      ResetPasswordComponent,
      DigitalInOutputsComponent,
      UserComponent,
      UserModalComponent,
        LoginComponent,
        RegisterComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

        // provider used to create fake backend
        fakeBackendProvider
  ],
  entryComponents: [
    UserModalComponent
  ],
    bootstrap: [AppComponent]
})

export class AppModule { }
