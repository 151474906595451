import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from '../_services/message.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class WeatherDataService {
  constructor
    (
    private http: HttpClient,
    private messageService: MessageService
    )
    { }
  private weatherDataUrl = 'api/weatherData';

  getAll(): Observable<models.IWeatherData[]> {
    return this.http.get<models.IWeatherData[]>(this.weatherDataUrl + "/GetAllWeatherData")
      .pipe(
      tap(_ => this.log('fetched weatherData')),
      catchError(this.handleError<models.IWeatherData[]>('GetAllWeatherData'))
      );
  }

  getAktuellWeatherData(): Observable<any> {
    return this.http.get<models.IWeatherData>(this.weatherDataUrl + "/GetAktuellWeatherData")
      .pipe(
        tap(_ => this.log('fetched weatherData')),
      catchError(this.handleError<models.IWeatherData>('GetAktuellWeatherData'))
      );
  }

  createWeatherData(weatherData: models.IWeatherData): Observable<models.IWeatherData> {
    return this.http.post<models.IWeatherData>(this.weatherDataUrl + "/CreateWeatherData", weatherData, httpOptions)
      .pipe(
      tap(_ => this.log(`added weatherData w/ id=${weatherData.id}`)),
      catchError(this.handleError<models.IWeatherData>('addUser'))
    );
  }

  updateWeatherData(weatherData: models.IWeatherData): Observable<any> {
    return this.http.put(this.weatherDataUrl + '/UpdateWeatherData', weatherData, httpOptions)
      .pipe(
      tap(_ => this.log(`updated user id=${weatherData.id}`)),
      catchError(this.handleError<models.IWeatherData>('updateWeatherData'))
    );
  }

  deleteWeatherData(weatherData: models.IWeatherData): Observable<any> {
    const id = weatherData.id;
    const url = `${this.weatherDataUrl}/deleteWeatherData?id=${id}`;
    return this.http.delete<any>(url, httpOptions).pipe(
      tap(_ => this.log(`deleted weatherData id=${id}`)),
      catchError(this.handleError<models.IWeatherData>('deleteWeatherData'))
    );
  }


  private log(message: string) {
    console.log("message", message);
    this.messageService.add(`WeatherDataService: ${message}`);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    console.log("Error", operation, result);
    return (error: any): Observable<T> => {
      console.error(error); 
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

}
